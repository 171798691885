import { h } from "preact";
import { Router } from "preact-router";

const url =
  process.env.NODE_ENV === "development"
    ? "https://pixel.dev"
    : "https://pixelchat.tv";

import Scene from "../routes/scene";
import Overlay from "../routes/overlay";
import Custom from "../routes/custom";

const App = () => {
  const handleRoute = (e) => {
    if (e.url === "/") {
      if (typeof window !== "undefined") {
        document.location = url;
      }
    } else if (e.url.startsWith("/m/")) {
      let un = e.url.split("/m/")[1];
      if (typeof window !== "undefined") {
        document.location = `https://multi.raredrop.co/pixelchat/${un}`;
      }
    }
  };

  return (
    <div id="app">
      <Router onChange={handleRoute}>
        <Scene path="/s/:userId/:sceneId" />
        <Overlay path="/:userId/:overlayId" />
        <Custom path="/custom/:userId/:overlayId" />
        <Custom path="/custom/private/:userId/:privateId" />
      </Router>
    </div>
  );
};

export default App;
